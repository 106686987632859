.header-logo {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #164863;
  cursor: pointer;
}

.dropdown-item:active{
  font-family: "poppins" !important;
  font-size: 12px;
}
.menu-option {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  cursor: pointer;
  background: white;
}

.search-size {
  height: 45px;
  width: 345px;
}







@media (max-width:768px) {
  .hide-mobile{
    display: none !important;
  }

  .hide-menu{
    display: none !important;
  }
}

@media (min-width:768px) {
  .hide-desktop{
    display: none !important;
  }

  
  .hide-menu{
    display: flex !important;
  }
}

.sidebar {
  background: #fff;
  border-right: 1px solid #ddd;
  padding: 20px;
  box-sizing: border-box;
  // max-width: 16rem;
  margin-top: 90px;
  margin-left: 0px;
  border-radius: 10px;
  position: fixed;
  height: 30vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 99999;
  width: 100%;
}

.sidebar-open {
  transform: translateX(0);
}

.menu2 {
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid black;
  padding: 12px;
}
