.accept-terms-container {
  padding: 20px;

  .exit-criteria-content {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 24px;

    p {
      margin-bottom: 16px;
    }

    br {
      margin: 8px 0;
    }

    // Style for numbered items
    ol,
    ul {
      margin-left: 20px;
      margin-bottom: 16px;
    }
  }

  .terms-acceptance {
    margin-bottom: 20px;

    .checkbox-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      input[type="checkbox"] {
        margin-right: 10px;
        cursor: pointer;
      }

      .checkbox-text {
        font-size: 14px;
        color: #333;
      }
    }
  }

  .submit-section {
    display: flex;
    justify-content: flex-end;

    .task-btn {
      min-width: 120px;
      margin-left: 10px;

      &:disabled {
        background-color: #e0e0e0;
        color: #888;
        cursor: not-allowed;
        opacity: 0.7;
        border: 1px solid #ccc;
      }
    }
  }
}
