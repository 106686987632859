.join-as-client {
  color: #164863;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding-bottom: 40px;
  padding-top: 150px;
}
