.password-field {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .password-label{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 23.484px */
  }
  
  .password-field .input-group {
    position: relative;
  }
  
  .password-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50x;
    padding: 10px 10px 10px 20px;
    align-items: center;
    flex-shrink: 0;

    border-radius: 10px;
    border: 1px solid #838383;
background: #FFF;
box-shadow: 0px 0.96576px 1.93151px 0px rgba(0, 0, 0, 0.08);
  }

  .password-input:focus{
    outline: none;

  }
  
  .toggle-password-visibility {
    position: absolute;
    right: 5px;
    top: 0px;
    border: none;
    background: transparent !important;
    cursor: pointer;
    outline: none;
    border-left: none !important;
    border-right: none !important;
    padding: 10px 10px 10px 20px;
  }
  