.no-data{
    font-weight: 700;
    color: gray;
    text-align: center;
   padding-top: 50px;
   font-size: 15px;
  }
  
  .no-data-desc{
    color: gray;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
  }