.payment-form {
  padding: 20px;

  .amount-display {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #164863;
  }

  .card-element-container {
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #f8f8f8;
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .cancel-btn {
    border-radius: 75px;
    border: 1.5px solid #164863;
    padding: 10px 20px;
    color: #164863;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
  }

  .pay-btn {
    border-radius: 75px;
    background: #164863;
    padding: 10px 20px;
    color: white;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;

    &:disabled {
      background: #cccccc;
      cursor: not-allowed;
    }
  }
}
