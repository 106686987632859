.fid-modal-content {
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.fid-modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.fid-modal-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.fid-face-image {
  width: 100px;
  height: 100px;
  margin: 0 auto 2rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.fid-instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  /* Center the text */
  margin-bottom: 2rem;

  p {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  ol {
    margin: 0;
    padding-left: 0;  /* Remove padding to center the list */
    list-style-position: inside;  /* Ensure list numbers are inside the content */

    li {
      margin-bottom: 0.5rem;
      text-align: left;  /* Align list items text to left for better readability */
      display: block;
    }
  }
}

.fid-camera {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.fid-webcam {
  width: 100%;
  max-width: 500px;
  border: 2px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.capture-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.capture-button:hover {
  background-color: #0056b3;
}

.fid-captured-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  .fid-captured-image {
    width: 100%;
    max-width: 500px;
    border: 2px solid #ccc;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

}

.fid-buttons-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-top: 1rem;
}
