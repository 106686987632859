.profile-banner {
  background: url("../../../assets/profile-banner.png");
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;

  .profile-pic-container {
    .profile-img {
      margin-top: 120px;
      margin-left: 20px;
    }

    .profile-img2 {

      margin-left: 20px;
    }

    .profile-pic-overlay {
      top: 120px;
      left: 22px;
    }

    .profile-pic-overlay2 {

      left: 22px;
    }
  }
}

.profile-bg {
  background-color: #f8f8f8;
}

.profile-pic-container {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: filter 0.3s ease;
  }

  .profile-img2 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: filter 0.3s ease;
  }

  .profile-pic-overlay {
    position: absolute;
    top: 120px;
    left: 22px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;

    span {
      color: white;
      font-size: 14px;
      font-weight: 500;
    }
  }



  .profile-pic-overlay2 {
    position: absolute;
    top: 0px;
    left: 22px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;

    span {
      color: white;
      font-size: 14px;
      font-weight: 500;
    }
  }



  &:hover {
    .profile-img {
      filter: brightness(0.8);
    }

    .profile-pic-overlay {
      opacity: 1;
    }

    .profile-pic-overlay2 {
      opacity: 1;
    }
  }
}

.profile-name {
  color: var(--Dark-01, #212427);
  font-family: "Poppins";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 100px;
  margin-left: 25px;
}

.profile-location {
  color: #575757;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 25px;
  margin-top: 10px;
}

.organization {
  color: var(--Dark-01, #212427);
  font-family: "Poppins";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 20px;
  margin-left: 25px;
}
