.loader {
  display: inline-block;
  width: 16px; // Adjust size as needed
  height: 16px; // Adjust size as needed
  border: 3px solid rgba(255, 255, 255, 0.3); // Light grey border
  border-radius: 50%;
  border-top-color: #fff; // White border top color
  animation: spin 1s ease-in-out infinite; // Animation for spinning effect
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}