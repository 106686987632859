.label {
  color: #727272;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.task-input {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0.5px 0px;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid var(--border-gray-02, #a9a6a6);
  background: var(--White, #fff);
  padding: 10px 10px 10px 20px;
}

.task-input:focus {
  outline: none;
}

.task-input-desc {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 0.5px 0px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid var(--border-gray-02, #a9a6a6);
  background: var(--White, #fff);
  padding: 10px 10px 10px 20px;
}

.task-input-desc:focus {
  outline: none;
}

.type-box {
  border: 1px solid grey;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
}

.task-btn {
  padding: 10px 20px;
  gap: 15px;
  border-radius: 75px;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;

  color: #ffffff;
  background-color: #164863;
  border: none;
}



.budget-input {
  height: 50px;

  padding: 0.5px 0px;

  border-radius: 10px;
  border-top: 1px solid var(--border-gray-02, #a9a6a6);
  border-left: 1px solid var(--border-gray-02, #a9a6a6);
  border-bottom: 1px solid var(--border-gray-02, #a9a6a6);
  border-right: none;
  background: var(--White, #fff);
  padding: 10px 10px 10px 20px;
  border-top-right-radius:0;
  border-bottom-right-radius: 0;

}

.budget-input:focus {
  outline: none;
}

.currency-select {
  height: 50px;
  padding: 0.5px 0px;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid var(--border-gray-02, #a9a6a6);
  border-left: none;
  border-bottom: 1px solid var(--border-gray-02, #a9a6a6);
  border-right: 1px solid var(--border-gray-02, #a9a6a6);
  background: var(--White, #fff);
  padding: 10px 10px 10px 20px;
}

.currency-select:focus {
  outline: none;
}

.skill-box {
  border-radius: 10px;
  border: 1px solid var(--border-gray-02, #a9a6a6);
  background: #fff;
  padding: 15px 21px 15px 26px;

  color: #838383;
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.skill-box2 {
  border-radius: 25px;
  border: 1px solid var(--border-gray-02, #a9a6a6);
  background: #fff;
  padding: 15px 21px 15px 26px;

  color: #838383;
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// .task-deadline{
//   width: 50%;
// }

@media (max-width:500px){
  .task-deadline{
    width: 100%;
  }

  .budget-input {

    width: 100%;
  }
  
}