.skills-container2 {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Adjust the gap between skills as needed */
}

.skill-wrapper {
  flex: 0 0 auto;
}

.qa-forum {
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;

  .qa-header {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }

  .ask-question-section {
    textarea {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      resize: vertical;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }

  .question-thread {
    background-color: #fff;
    border-color: #e0e0e0 !important;

    .question-header {
      strong {
        color: #333;
      }

      .text-muted {
        font-size: 0.85rem;
      }

      .badge {
        font-size: 0.75rem;
        padding: 0.35em 0.65em;
      }
    }
.q-name{
  font-size: 16px;
}
    .question-content {

      white-space: pre-wrap;
      color: #000;
      font-family: "Poppins";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }

    .answer {
      border: 1px solid #e9ecef;
      background-color: #f8f9fa !important;

      .answer-content {
        color: #000000a1;
        font-family: "Poppins";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }

    .btn-link {
      color: #007bff;
      text-decoration: none;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .task-btn {
    &.btn-secondary {
      background-color: #6c757d;
      border-color: #6c757d;
      color: white;

      &:hover {
        background-color: #5a6268;
        border-color: #545b62;
      }
    }
  }
}
