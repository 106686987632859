.advanced-option {
  padding: 5px;
  color: var(--Dark-01, var(--font---gray, #212427));
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.invite-sub-contract {
  display: flex;
  width: 90px;
  height: 34px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid var(--border-gray, #E4E4E4);
  background: var(--blue-01, #164863);

  button {
    color: #FFF;
    font-family: Poppins;
    font-size: 15.786px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.selected-user-label {
  max-width: 400px;
  background-color: "#f9f9f9";
}

.close-selected-user-icon {
  top: -10px;
  right: -10px;
  cursor: "pointer";
  font-size: 1.5rem;
}