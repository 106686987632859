.profile-banner {
  background: url("../../../assets/profile-banner.png");
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-img {
  margin-top: 120px;
  margin-left: 20px;
  border-radius: 50%;
}

.profile-img2 {
  margin-left: 20px;
}


.profile-bg {
  background-color: #f8f8f8;
}

.profile-name {
  color: var(--Dark-01, #212427);
  font-family: "Poppins";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 100px;
  margin-left: 25px;
}

.profile-location {
  color: #575757;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 25px;
  margin-top: 10px;
}

.organization {
  color: var(--Dark-01, #212427);
  font-family: "Poppins";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 20px;
  margin-left: 25px;
}
