// * {
//   color: #575757;
//   font-family: "Poppins";
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
// }

.skills-container {
  position: relative;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 20px;
  margin-top: 40px; // Added margin for spacing
  margin-bottom: 40px; // Added margin for spacing
}

.background-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.small-square {
  width: 24px;
  height: 24px;
  padding: 2px;
  position: absolute;
  left: calc(100% - 120px);
  top: 76px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .small-square-inner {
    width: 20px;
    height: 20px;
    background: #164863;
  }
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f8f8f8;
  border-radius: 20px;
}

.skills-content {
  position: relative;
  padding: 40px;

  .skills-title {
    color: var(--Dark-01, #212427);
    font-size: 20px;
    margin-bottom: 20px;
  }

  .skill {
    background: #d9d9d9;
    border-radius: 50px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    margin-right: 20px;
    margin-bottom: 10px;

    .skill-text {
      color: #212427;
      font-size: 14px;
    }
  }
  .image-button {
    width: 34px;
    height: 34px;
    padding: 5px;
    border-radius: 9999px;
    border: 2px solid #164863;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: calc(100% - 140px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease; // Smooth transition for hover effect

    &:hover {
      background-color: #e8e3e3;
    }

    .button-image {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
    }
  }
}
