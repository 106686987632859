.al-parent-container {
  display: flex;
  flex-direction: column;
  gap: 16px; // Adjust gap as needed for spacing between items
}

.al-container-background {
  width: 509px;
  height: 83px;
  padding: 12px;
  background: white;
  border-radius: 10px;
  border: 1px solid #a9a6a6;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.al-container {
  width: 444px;
  height: 45.27px;
  position: relative;
}

.al-box {
  width: 168px;
  height: 45.27px;
  position: absolute;
  overflow: visible;
}

.al-box1 {
  left: 215px;
  top: 0;
}

.al-box2 {
  left: 0;
  top: 0;
}

.al-inner-box {
  width: 100%;
  height: 100%;
  padding: 12px;
  background: white;
  border-radius: 10px;
  border: 1px solid #a9a6a6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.al-select {
  width: 100%;
}

.al-rotated-box {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 410px;
  top: 25px;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.al-rotated-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9999px;
  transition: transform 0.3s ease;
}

.al-rotated-box:hover .al-rotated-image {
  transform: scale(1.1);
}

.al-language-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; // Adjust gap as needed for spacing between items
}

.al-language-list .al-container-background {
  flex: 1 1 calc(50% - 16px); // Adjust to fit two columns with the gap
  box-sizing: border-box;
  max-width: 509px; // Ensure that items do not take full width
}

.al-button-container {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 16px); // Align with the right edge of the loop items
  margin-top: 16px; // Add gap between save button and loop items
  padding-right: 16px;
}

.al-save-button {
  height: 51px;
  padding: 10px 30px;
  background: #164863;
  border: none;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #123a50;
  }
}

.al-button-text {
  color: white;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 20px;
}
