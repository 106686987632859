.modal-title {
  color: var(--Dark-01, #212427);
  font-family: "Poppins";
  font-size: 21px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-header {
  border-bottom: none !important;
}
.modal-content {
  background-color: #f8f8f8 !important;


}

