.progress-txt {
  color: #727272;
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
}
.status-section2 {
  background-color: #f8f8f8;
  padding: 30px;
  margin-top: 30px;
  border-radius: 20px;
}
.status-lbl {
  color: #727272;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}


.status-date-time{
  font-size: 15px;
  font-family: 'poppins';
  font-weight: 400;
}