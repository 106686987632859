.profile-picture-selector {
  padding: 20px;

  .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    .tab {
      padding: 10px 20px;
      border: none;
      background-color: #f0f0f0;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #e0e0e0;
      }

      &.active {
        background-color: #007bff;
        color: white;
      }
    }
  }

  .preview {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .preview-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #007bff;
    }
  }

  .content {
    .upload-section {
      display: flex;
      justify-content: center;
      padding: 20px;
      position: relative;

      .file-input {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
      }

      .file-input-label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 12px 24px;
        background-color: #007bff;
        color: white;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 500;
        border: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &:hover {
          background-color: #0056b3;
          transform: translateY(-1px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .file-name {
        margin-top: 10px;
        font-size: 14px;
        color: #666;
        text-align: center;
      }
    }

    .camera-section {
      display: flex;
      justify-content: center;
      padding: 20px;

      .capture-btn {
        min-width: 200px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 25px;
        padding: 12px 24px;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &:hover {
          background-color: #0056b3;
          transform: translateY(-1px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .avatar-section {
      .avatar-group {
        margin-bottom: 20px;

        h4 {
          margin-bottom: 10px;
          color: #333;
          text-align: center;
        }

        .avatar-list {
          display: flex;
          gap: 15px;
          flex-wrap: wrap;
          justify-content: center;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            cursor: pointer;
            border: 2px solid transparent;
            transition: all 0.3s ease;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            &:hover {
              transform: scale(1.05);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            }

            &.selected {
              border-color: #007bff;
            }
          }
        }
      }
    }
  }
}
