.intro-box {
  box-shadow: 0px 0px 4.6px 0px #00000040;
  border-radius: 30px;
  padding: 20px;
}

.intro-header {
  font-family: Georgia;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: #164863;
  text-transform: capitalize;
}

.intro-description {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
}

.intro-button {
  padding: 8px 31px;
  gap: 10px;
  border-radius: 50px;
  background-color: #164863;
  color: white;
  border: none;

  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.fid-retry-button {
  padding: 8px 31px;
  gap: 10px;
  border-radius: 50px;
  background-color: #631628;
  color: white;
  border: none;

  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.client-dash-header {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
}
.client-dash-description {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #212427;
}

.dashboard-header2 {
  font-family: Georgia;
  font-size: 30px;
  font-weight: 400;
  line-height: 45.45px;
  text-align: left;
}

.post-time {
  color: #676767;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

