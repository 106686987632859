.coworker-filter {
    border-radius: 5px;
    border: 1px solid var(--border-gray, #E4E4E4);
    background: #F8F8F8;
    width: 100%;
    height: 50px;
}

.edit-cursor{
    cursor: pointer;
}