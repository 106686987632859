.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &-small {
    .spinner-loader {
      width: 20px;
      height: 20px;
    }
  }

  &-medium {
    .spinner-loader {
      width: 40px;
      height: 40px;
    }
  }

  &-large {
    .spinner-loader {
      width: 60px;
      height: 60px;
    }
  }
}

.spinner-loader {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #164863; // Use your primary color here
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
