.chat-container {
  background-color: #f8f8f8;
  padding-left: 10px;
  padding-right: 10px;
  height: 570px;
  overflow: auto;
}

.chat-box {
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #a1a1a1;
}

.chat-box-inner {
  margin-top: 10px;
  margin-bottom: 10px;
  // background-color: white;
  cursor: pointer;
  padding: 10px;
}


.chat-box-inner-active {

  background-color: white;

}

.chat-title {
  font-family: "poppins";
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 15px;
}
.notify-indicator {
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  background-color: #a9a6a6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.chatting-container {
  background-color: #f8f8f8;

}


.messages-container {
    height: 400px;
    overflow: auto;
}

.message-name{
    font-family: 'poppins';
    font-size: 16px;
    font-weight: 600;
}

.message-rs {
  border: 1px solid #143f57;
  border-radius: 10px;
  padding: 5px;
  width: 360px;
  font-size: 12px;
  line-height: 20px;
  font-family: "poppins";
  color: #143f57;
}

.message-rp {
    border: 1px solid #143f57;
    background: #143f57;
    border-radius: 10px;
    padding: 5px;
    width: 360px;
    font-size: 12px;
    line-height: 20px;
    font-family: "poppins";
    color: white;
  }

.message-time {
  color: #333333;
  font-size: 10px;
  font-family: "poppins";
  padding-top: 10px;
}
.chatting-header {
  height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid #a9a6a6;
}



.chat-input{
    width: 100%;
height: 55px;
border: none;
outline: none;
resize: none;

}

.chat-input2{
  width: 100%;
height: 95px;
border: none;
outline: none;
resize: none;
}

.chatting-box{
    border-radius: 0px 8px 8px 8px;
    border: 1px solid rgba(212, 212, 212, 1);
    background-color: white;
}

.chat-message{
    color: #A1A1A1;
}

.avtr{
  width: 100%;
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(16, 61, 16);
  border-radius: 50%;
  text-transform: uppercase;
}