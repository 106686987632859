.payment-history {
    padding: 24px;

    h2 {
        margin-bottom: 24px;
    }

    .status {
        text-transform: capitalize;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 14px;

        &.succeeded {
            background-color: #f6ffed;
            color: #52c41a;
        }

        &.processing {
            background-color: #e6f7ff;
            color: #1890ff;
        }

        &.failed {
            background-color: #fff1f0;
            color: #f5222d;
        }

        &.pending {
            background-color: #fffbe6;
            color: #faad14;
        }
    }
}
