.register-type-bg{
    width: 100%;
    height: 1000px;
}


.register-type-heading{
    font-family: Georgia;
font-size: 44px;
font-weight: 400;
line-height: 52.72px;

}

.register-type-description{
    font-family: 'Poppins';
font-size: 15px;
font-weight: 300;
line-height: 22.5px;

}

.option-frame-clicked{
    border: 2.67px solid #164863;
    height: 250px;
    width: 100%;
    border-radius:14.55px;
}

.option-frame{
    border: 2.67px solid #989898;
    height: 250px;
    width: 100%;
    border-radius:14.55px;
}

.option-padding{
    padding-left: 5%;
    padding-right:5%;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}


.register-type-option{
    font-family: 'Poppins';
font-size: 23.28px;
font-weight: 500;
line-height: 34.92px;
text-align: center;


}

.register-type-btn{
    width: 40%;
height:50px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 100px;
background: #164863;

color: white;
font-family: 'Poppins';
font-size: 16px;
font-weight: 600;
line-height: 30px;
border: none;

}
    /* Styles */
    .col-0{
        display: none !important;
    }




/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
        /* Styles */
        .col-0{
            display: none !important;
        }



  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    /* Styles */
        /* Styles */
        .col-0{
            display: none !important;
        }


  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 
    /* Styles */
        /* Styles */
        .col-0{
            display: none !important;
        }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) { 
    /* Styles */
    .col-0{
        display: flex !important;
    }

    .imageContainer{
        height: 100vh;
    }

    .register-type-bg{
        width: 100%;
        height: 900px;
    }
    
  }
  
  /* Extra extra large devices (larger desktops, 1400px and up) */
  @media (min-width: 1930px) { 
    /* Styles */

    .imageContainer{
        height: 100vh;
    }

    
  }