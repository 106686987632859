.download-txt {
  color: #214fd6;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.no-submit {
  font-weight: 400;
  font-size: 12px;
  color: #0000007b;
}
