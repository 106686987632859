.payment-settings-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .settings-header {
    margin-bottom: 2rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    p {
      color: #666;
    }
  }

  .account-status-section {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 24px;

    .status-header {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      h3 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-right: 1rem;
        margin-bottom: 0;
      }

      .status-badge {
        padding: 0.25rem 1rem;
        border-radius: 20px;
        font-size: 0.875rem;
        font-weight: 500;

        &.verified {
          background-color: #e6f4ea;
          color: #1e7e34;
        }

        &.pending {
          background-color: #fff3e0;
          color: #f57c00;
        }
      }
    }

    .account-info {
      background-color: #f8f9fa;
      border-radius: 8px;
      padding: 1.5rem;
      margin-bottom: 2rem;

      h4 {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 1.25rem;
        color: #2c3e50;
      }

      .info-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1.5rem;

        .info-item {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .info-label {
            font-size: 0.875rem;
            color: #6c757d;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.5px;
          }

          .info-value {
            font-size: 1rem;
            color: #2c3e50;
            font-weight: 500;
            word-break: break-word;
          }
        }
      }
    }

    .requirements-section {
      margin-bottom: 2rem;

      h4 {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 0.75rem 1rem;
          background-color: #fff3e0;
          border-radius: 4px;
          margin-bottom: 0.5rem;
          color: #f57c00;
          font-size: 0.875rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .capabilities-section {
      margin-bottom: 2rem;

      h4 {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 1.25rem;
      }

      .capabilities-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;

        .capability-item {
          background-color: #f8f9fa;
          padding: 1rem;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .capability-label {
            font-size: 0.875rem;
            color: #6c757d;
            font-weight: 500;
          }

          .capability-status {
            font-size: 0.875rem;
            font-weight: 500;
            padding: 0.25rem 0.75rem;
            border-radius: 20px;
            display: inline-block;
            text-align: center;

            &.active {
              background-color: #e6f4ea;
              color: #1e7e34;
            }

            &.inactive {
              background-color: #f8d7da;
              color: #dc3545;
            }
          }
        }
      }
    }

    .actions-section {
      display: flex;
      justify-content: flex-end;

      button {
        min-width: 200px;
      }
    }

    .onboarding-section {
      text-align: center;
      padding: 3rem 1rem;

      h3 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      p {
        color: #666;
        margin-bottom: 2rem;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      button {
        min-width: 200px;
      }
    }
  }
}
