.upd-task-heading {
  color: var(--Dark-01, #212427);
  font-family: "Poppins";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.submit-bg{
    border-radius: 20px;
background: #F8F8F8;
}