.search-input-box {
  outline: none;
  border: 1px solid #838383;
  border-left: none !important;
  border-radius: 20px;
}

.input-group-text {
  background-color: white !important;
  border: 1px solid #838383 !important;
  border-right: none !important;
  border-radius: 0 !important;
  height: 45px;

  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}
