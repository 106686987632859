.grey-container {
    border-radius: 20px;
    border: 1px solid var(--border-gray, #E4E4E4);
    background: #F8F8F8;
    padding: 30px;
}

.grey-container div div {
    color: var(--Primary, #0F1014);
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 120% */
}

.blue-container {
    border-radius: 20px;
    border: 1px solid var(--border-gray, #E4E4E4);
    background: #DBF2FF;
    padding: 44px;
    text-align: center;
}

.coworker-filter {
    border-radius: 5px;
    border: 1px solid var(--border-gray, #E4E4E4);
    background: #F8F8F8;
    width: 100%;
    height: 50px;
}

.coworker-filter:focus {
outline: none !important;
}

.coworker-select {
    border-radius: 5px;
    border: 1px solid var(--border-gray, #E4E4E4);
    background: #F8F8F8;
    width: 100%;
    height: 45px;
    padding: 5px;
}

.search-bar-radious {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border: 1px solid var(--border-gray, #E4E4E4);
    background: #F8F8F8;
}

.search-size3 {
    height: 45px;
    width: 100%;
}

.space-between {
    justify-content: space-between;
}

.co-work-search {
    width: 300px;
    height: 50px;
    gap: 10px;
    border-radius: 100px;
    border: 2px solid #164863;
    color: #164863;
    background-color: white;
    font-size: 16px;
    font-weight: 600;
}

.co-work-add {
    width: 300px;
    height: 50px;
    gap: 10px;
    border-radius: 100px;
    border: 4px solid #164863;
    color: white;
    background-color: #164863;
    font-size: 16px;
    font-weight: 600;
}

.invitaion-btn {
    height: 37px;
    padding: 7px 17px 6px 16px;
    border-radius: 50px;
    background: var(--blue-01, #164863);
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.invite-text {
    p {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    /* 150% */
}

.invite-text-header {
    color: #164863;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}